import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Staff = ({ staffGroup }) => {
  return (
    <div className="container space-y-10 lg:space-y-20">
      {staffGroup.length > 0 &&
        staffGroup.map((item, index) => {
          const { heading, staffMembers } = item
          return (
            <div key={`${heading} ${index}`}>
              {heading && (
                <div className="mb-10 md:mb-12 lg:mb-15">
                  <h3 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
                    {heading}
                  </h3>
                </div>
              )}
              {staffMembers.length > 0 && (
                <div className="grid grid-cols-12 gap-y-15 lg:gap-x-10 lg:gap-y-20">
                  {staffMembers.map((item, index) => {
                    const { title, textPrimary, bodyStandard, image } = item

                    return (
                      <article
                        className="col-span-12 lg:col-span-3 xl:col-span-4"
                        key={index}
                      >
                        <div>
                          <div className="aspect-w-1 aspect-h-1 mb-10">
                            <div className="rounded-br-3xl transform-gpu overflow-hidden mb-4 bg-black bg-opacity-25">
                              {image[0] && image[0].localFile && (
                                <GatsbyImage
                                  className="w-full h-full object-cover rounded-br-3xl"
                                  image={getImage(image[0].localFile)}
                                  alt={image[0].title}
                                  style={{ position: "absolute" }} // To override Gatsby Image for aspect ratio
                                />
                              )}
                            </div>
                          </div>
                          <div className="lg:pr-10">
                            {title && (
                              <h4 className="text-xl font-semibold mb-3">
                                {title}
                                {textPrimary && <span>, {textPrimary}</span>}
                              </h4>
                            )}
                            {bodyStandard && (
                              <div
                                className="text-sm space-y-4"
                                dangerouslySetInnerHTML={{
                                  __html: bodyStandard,
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </article>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default Staff
