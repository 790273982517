import React, { useState } from "react"
import PropTypes from "prop-types"

import AccordionItem from "./AccordionItem"

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const itemClickHandler = index => {
    setActiveIndex(prevActiveIndex => {
      if (prevActiveIndex === index) {
        return null
      }

      return index
    })
  }

  return (
    <div className="border-b border-grey">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          item={item}
          isActive={index === activeIndex}
          clickHandler={() => itemClickHandler(index)}
        />
      ))}
    </div>
  )
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ),
}

export default Accordion
