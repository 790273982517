import React from "react"
import PropTypes from "prop-types"

const bgClassMap = {
  0: "bg-green bg-opacity-50",
  1: "bg-black bg-opacity-40",
  2: "bg-green bg-opacity-70",
  3: "bg-green bg-opacity-100",
}

const borderClassMap = {
  0: "border-green border-opacity-50",
  1: "border-black border-opacity-50",
  2: "border-green border-opacity-70",
  3: "border-green border-opacity-100",
}

const InfoSection = ({ heading, items, body }) => {
  if (!items.length) {
    return null
  }

  return (
    <div className="container space-y-12">
      {heading && <h2 className="text-2xl font-bold">{heading}</h2>}
      <div className="grid grid-cols-12 gap-y-15 md:gap-x-10">
        {items.map((item, index) => {
          const { id, icon, heading, body } = item

          return (
            <div
              key={id}
              className="col-span-12 pt-12 md:col-span-6 2xl:col-span-3"
            >
              <div
                className={`h-full px-4 pt-0 pb-12 ${bgClassMap[index]} rounded-tr-3xl text-center space-y-4 xs:px-8 xs:space-y-6`}
              >
                <div
                  className={`inline-block w-24 h-24 bg-white border-2 ${borderClassMap[index]} rounded-full -mt-12 overflow-hidden transform translate-z-0`}
                >
                  {icon[0] && icon[0].localFile && (
                    <img
                      className="w-full h-full object-cover"
                      src={icon[0].localFile.publicURL}
                      alt={heading}
                    />
                  )}
                </div>
                {heading && <h3 className="text-2xl font-bold">{heading}</h3>}
                <hr className="border-t-2" />
                {body && (
                  <div
                    className="rte sm:text-xl"
                    dangerouslySetInnerHTML={{ __html: body }}
                  />
                )}
              </div>
            </div>
          )
        })}
      </div>
      {body && (
        <div className="rte" dangerouslySetInnerHTML={{ __html: body }} />
      )}
    </div>
  )
}

InfoSection.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.arrayOf(PropTypes.shape()),
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ).isRequired,
  body: PropTypes.string,
}

export default InfoSection
