import { useState, useEffect, useCallback } from "react"
import { encodeUrl } from "../components/FlexibleContentStandard/FCStandard_Components/utils"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const useForm = defaultState => {
  const [state, setState] = useState(defaultState)
  const [isValid, setIsValid] = useState(false)

  // validates the form's fields
  useEffect(() => {
    // ensure each field is populated
    for (const field in state) {
      if (!state[field].trim()) {
        setIsValid(false)
        return
      }
    }

    // ensure email is valid format
    if (state["email"] && !validateEmail(state["email"])) {
      setIsValid(false)
      return
    }

    setIsValid(true)
  }, [state])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleRecaptchaVerify = useCallback(token => {
    setState(prev => ({ ...prev, "g-recaptcha-response": token }))
  }, [])

  return {
    state,
    setState,
    isValid,
    setIsValid,
    handleChange,
    handleRecaptchaVerify,
  }
}

export default useForm
