import React from "react"
import PropTypes from "prop-types"

import IconCircleTick from "../SVG/IconCircleTick"

const FeatureList = ({ items }) => {
  if (!items.length) {
    return null
  }

  return (
    <ul className="space-y-8">
      {items.map((item, index) => {
        if (item === "") {
          return null
        }
        return (
          <li key={index} className="flex">
            <div className="flex-shrink-0 w-6 h-6 mr-5 text-green">
              <IconCircleTick />
            </div>
            {item}
          </li>
        )
      })}
    </ul>
  )
}

FeatureList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
}

export default FeatureList
