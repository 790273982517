import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Columns from "../../Columns/Columns"
import Column from "../../Columns/Column"

const TextWithImage = ({ heading, body, image, imageCaption }) => {
  if (!body) {
    return null
  }

  return (
    <Columns heading={heading}>
      <Column>
        <div
          className="rte lg:pr-12"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </Column>
      <Column>
        <figure>
          <div className="aspect-w-3 aspect-h-2 rounded-br-3xl overflow-hidden">
            {image[0] && image[0].localFile && (
              <GatsbyImage
                className="w-full h-full absolute!important"
                image={getImage(image[0].localFile)}
                alt={image[0].title}
              />
            )}
          </div>
          {imageCaption && (
            <figcaption className="text-md italic text-black text-opacity-75 mt-3">
              {imageCaption}
            </figcaption>
          )}
        </figure>
      </Column>
    </Columns>
  )
}

TextWithImage.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
}

export default TextWithImage
