import React from "react"
import PropTypes from "prop-types"

import FeatureList from "../../FeatureList/FeatureList"

const FeaturesList = ({ heading, lead, featuresList }) => {
  if (!featuresList?.length) {
    return null
  }

  const featureItems = featuresList.map(item => item.feature)
  const featureItemsHalf = Math.ceil(featureItems.length / 2)
  const featureItemsFirstHalf = featureItems.slice(0, featureItemsHalf)
  const featureItemsSecondHalf = featureItems.slice(-featureItemsHalf + 1)

  return (
    <div className="container">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
        {(heading || lead) && (
          <div className="mb-10 md:mb-12 lg:col-span-6 lg:mb-15">
            {heading && (
              <h2 className="text-2xl md:text-3xl lg:text-4xl">{heading}</h2>
            )}
            {lead && (
              <div
                className="rte mt-10 md:mt-12 lg:mt-15"
                dangerouslySetInnerHTML={{ __html: lead }}
              ></div>
            )}
          </div>
        )}
        <div className="lg:col-start-1 lg:col-span-5">
          <FeatureList items={featureItemsFirstHalf} />
        </div>
        <div className="mt-8 lg:col-start-7 lg:col-span-5 lg:mt-0">
          <FeatureList items={featureItemsSecondHalf} />
        </div>
      </div>
    </div>
  )
}

FeaturesList.propTypes = {
  heading: PropTypes.string,
  lead: PropTypes.string,
  featuresList: PropTypes.arrayOf(
    PropTypes.shape({
      feature: PropTypes.string,
    })
  ),
}

export default FeaturesList
