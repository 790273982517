import React from "react"

const IconCircleTick = () => (
  <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.5607 10.0607C18.1464 9.47487 18.1464 8.52513 17.5607 7.93934C16.9749 7.35355 16.0251 7.35355 15.4393 7.93934L10.5 12.8787L8.56066 10.9393C7.97487 10.3536 7.02513 10.3536 6.43934 10.9393C5.85355 11.5251 5.85355 12.4749 6.43934 13.0607L9.43934 16.0607C10.0251 16.6464 10.9749 16.6464 11.5607 16.0607L17.5607 10.0607Z"
    />
  </svg>
)

export default IconCircleTick
