import React from "react"
import PropTypes from "prop-types"

import Columns from "../../Columns/Columns"
import Column from "../../Columns/Column"

const Text = ({ heading, textColumn }) => {
  return (
    <Columns heading={heading}>
      {textColumn.map(item => {
        if (!item.body) {
          return null
        }
        return (
          <Column key={item.id}>
            <div
              className="rte lg:pr-12"
              dangerouslySetInnerHTML={{ __html: item.body }}
            ></div>
          </Column>
        )
      })}
    </Columns>
  )
}

Text.propTypes = {
  heading: PropTypes.string,
  textColumn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
}

export default Text
