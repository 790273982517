import React from "react"
import PropTypes from "prop-types"

const Columns = ({ heading, classes, children }) => (
  <div className={classes}>
    <div className="container">
      {heading && (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <div className="mb-10 col-span-7 md:mb-12 lg:mb-15">
            <h3 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
              {heading}
            </h3>
          </div>
        </div>
      )}
      <div className="lg:grid lg:grid-flow-col lg:auto-cols-fr lg:gap-x-5 space-y-10 lg:space-y-0">
        {children}
      </div>
    </div>
  </div>
)

Columns.propTypes = {
  heading: PropTypes.string,
}

export default Columns
