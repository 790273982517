import React from "react"

const IconCircleMinus = () => (
  <svg className="w-full h-full fill-current" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM7.5 10.5C7.10218 10.5 6.72064 10.658 6.43934 10.9393C6.15804 11.2206 6 11.6022 6 12C6 12.3978 6.15804 12.7794 6.43934 13.0607C6.72064 13.342 7.10218 13.5 7.5 13.5H16.5C16.8978 13.5 17.2794 13.342 17.5607 13.0607C17.842 12.7794 18 12.3978 18 12C18 11.6022 17.842 11.2206 17.5607 10.9393C17.2794 10.658 16.8978 10.5 16.5 10.5H7.5Z"
    />
  </svg>
)

export default IconCircleMinus
