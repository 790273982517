import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FeaturedCaseStudy = ({ caseStudy }) => {
  if (!caseStudy || !caseStudy[0]) {
    return null
  }

  const { image, title, summary, uri } = caseStudy[0]

  return (
    <div className="container">
      <div className="lg:relative lg:pt-20">
        <div className="sm:grid sm:grid-cols-12 sm:gap-x-5 lg:mb-20 lg:absolute lg:inset-0">
          <div className="bg-grey aspect-w-2 aspect-h-1 sm:col-span-11 lg:col-span-7 lg:pb-0">
            <GatsbyImage
              className="w-full h-full absolute!important object-cover"
              image={getImage(image[0].localFile)}
              alt={image[0].title}
            />
          </div>
        </div>
        <div className="sm:grid sm:grid-cols-12 sm:gap-x-5 sm:-mt-12 relative lg:mt-0">
          <div className="sm:col-start-2 sm:col-span-11 lg:col-start-6 lg:col-span-7">
            <div className="p-8 sm:p-12 xl:pr-24 bg-green rounded-br-3xl text-white xl:flex xl:flex-col xl:min-h-115">
              {title && (
                <h2 className="mb-7 text-2xl lg:text-3xl xl:text-4xl font-semibold">
                  {title}
                </h2>
              )}
              {summary && (
                <p className="mb-7 sm:text-lg lg:text-xl xl:text-2xl">
                  {summary}
                </p>
              )}
              {uri && (
                <Link
                  to={`${uri}/`}
                  className="inline-block pb-1 border-b-2 transition hover:text-black sm:text-lg lg:text-xl xl:text-2xl font-semibold xl:self-start xl:mt-auto"
                >
                  Read the case study
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FeaturedCaseStudy.propTypes = {
  caseStudy: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape(),
      title: PropTypes.string,
      summary: PropTypes.string,
      uri: PropTypes.string,
    })
  ),
}

export default FeaturedCaseStudy
