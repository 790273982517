import React from "react"
import useForm from "../../hooks/useForm"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"

const defaultState = {
  name: "",
  title: "",
  email: "",
  phone: "",
  organization: "",
  location: "",
  idea: "",
  challenges: "",
  help: "",
  "g-recaptcha-response": "",
}

// can only access GoogleReCaptcha context from a child component
const ConnectForm = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
    >
      <ConnectFormWithoutReCaptcha />
    </GoogleReCaptchaProvider>
  )
}

const ConnectFormWithoutReCaptcha = () => {
  const { state, isValid, handleChange, handleRecaptchaVerify } =
    useForm(defaultState)

  return (
    <form
      name="Contact"
      method="POST"
      action={process.env.GATSBY_CONNECT_FORMSPREE_ENDPOINT}
    >
      <div className="grid grid-cols-12 gap-x-4 gap-y-6 mt-12">
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="name">
            Your name *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            name="name"
            type="text"
            onChange={handleChange}
            value={state.name}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-4 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="title">
            Your job title *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            name="title"
            type="text"
            onChange={handleChange}
            value={state.title}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="name">
            Your email *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            name="email"
            type="email"
            onChange={handleChange}
            value={state.email}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-4 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="title">
            Your phone number *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            name="phone"
            type="text"
            onChange={handleChange}
            value={state.phone}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="organization">
            The organisation you work for *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            name="organization"
            type="text"
            onChange={handleChange}
            value={state.organization}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-4 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="location">
            Your location *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            name="location"
            type="text"
            onChange={handleChange}
            value={state.location}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-6">
          <label className="font-semibold block mb-2" htmlFor="idea">
            Tell us about your idea, project or opportunity *
          </label>
          <textarea
            className="bg-black bg-opacity-20 min-h-32 p-4 w-full"
            name="idea"
            type="text"
            onChange={handleChange}
            value={state.idea}
            required
          ></textarea>
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-6">
          <label className="font-semibold block mb-2" htmlFor="challenge">
            What are your biggest challenges or obstacles in progressing? *
          </label>
          <textarea
            className="bg-black bg-opacity-20 min-h-32 p-4 w-full"
            name="challenges"
            type="text"
            onChange={handleChange}
            value={state.challenges}
            required
          ></textarea>
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-6">
          <label className="font-semibold block mb-2" htmlFor="help">
            What help do you think you need? *
          </label>
          <textarea
            className="bg-black bg-opacity-20 min-h-32 p-4 w-full"
            name="help"
            type="text"
            onChange={handleChange}
            value={state.help}
            required
          ></textarea>
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-6">
          <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
          <input
            type="hidden"
            name="g-recaptcha-response"
            defaultValue={state["g-recaptcha-response"]}
            required
          />
          <button
            className="uppercase tracking-wider font-semibold text-white bg-green w-full p-5 disabled:opacity-50 disabled:cursor-not-allowed"
            type="submit"
            disabled={!isValid}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}

export default ConnectForm
