import React from "react"
import PropTypes from "prop-types"

import Accordion from "../../Accordion/Accordion"

const FAQSection = ({ heading, lead, accordion }) => {
  return (
    <div className="container">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <div className="mb-10 col-span-4">
          {heading && (
            <h3 className="mb-10 text-3xl md:text-4xl lg:text-5xl font-semibold">
              {heading}
            </h3>
          )}
          {lead && (
            <div
              className="space-y-4"
              dangerouslySetInnerHTML={{ __html: lead }}
            ></div>
          )}
        </div>
        <div className="col-start-6 col-span-6">
          <Accordion items={accordion} />
        </div>
      </div>
    </div>
  )
}

FAQSection.propTypes = {
  heading: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  accordion: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    })
  ),
}

export default FAQSection
