import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Columns from "../../Columns/Columns"
import Column from "../../Columns/Column"

const Image = ({ heading, body, image }) => {
  return (
    <Columns>
      <Column>
        <div className="rte">
          {heading && <h2>{heading}</h2>}
          {image[0]?.localFile && (
            <GatsbyImage
              image={getImage(image[0].localFile)}
              alt={image[0].title}
            />
          )}
          {body && (
            <div
              className="rte"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          )}
        </div>
      </Column>
    </Columns>
  )
}

Image.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.shape(),
}

export default Image
