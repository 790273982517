import React from "react"
import PropTypes from "prop-types"

import IconCirclePlus from "../SVG/IconCirclePlus"
import IconCircleMinus from "../SVG/IconCircleMinus"

const AccordionItem = ({ item, isActive, clickHandler }) => {
  const { heading, body } = item
  if (!heading && !body) {
    return null
  }

  return (
    <>
      <button
        className="flex w-full py-6 border-t border-grey cursor-pointer text-left font-semibold sm:items-center sm:text-lg"
        onClick={clickHandler}
      >
        <div className="flex-shrink-0 w-6 h-6 mr-3 text-green sm:mr-5">
          {isActive ? <IconCircleMinus /> : <IconCirclePlus />}
        </div>
        {heading && <div>{heading}</div>}
      </button>
      <div className={`sm:pl-11 ${isActive ? "block" : "hidden"}`}>
        {body && (
          <div
            className="rte pb-10"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        )}
      </div>
    </>
  )
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }),
  isActive: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
}

export default AccordionItem
