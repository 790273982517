import React from "react"

import {
  FAQSection,
  Text,
  Image,
  Staff,
  FormSection,
  FeaturedCaseStudy,
  FeaturesList,
  TextWithImage,
  InfoSection,
} from "./FCStandard_Components"

import { bgClass } from "./FCStandard_Components/utils"

const FlexibleContentStandard = ({ ...props }) => {
  const {
    isFirst,
    isLast,
    hasSamePrevBgColour,
    hasSameNextBgColour,
    typeHandle,
    backgroundColour,
  } = props

  const contentMap = {
    faq: FAQSection,
    text: Text,
    image: Image,
    staff: Staff,
    formSection: FormSection,
    featuredCaseStudy: FeaturedCaseStudy,
    featuresList: FeaturesList,
    textWithImage: TextWithImage,
    infoSection: InfoSection,
  }

  const Component = contentMap[typeHandle]

  if (!typeHandle || !Component) {
    return null
  }

  let sectionClasses = `py-16 md:py-32 ${bgClass(backgroundColour)}`
  if (isFirst) sectionClasses += " pt-28 md:pt-56"
  if (isLast) sectionClasses += " pb-28 md:pb-56"
  if (hasSamePrevBgColour) sectionClasses += " pt-8 md:pt-16"
  if (hasSameNextBgColour) sectionClasses += " pb-8 md:pb-16"

  return (
    <section className={sectionClasses}>
      <Component {...props} />
    </section>
  )
}

export default FlexibleContentStandard
