import React from "react"
import PropTypes from "prop-types"

import ConnectForm from "../../ConnectForm/ConnectForm"
import ContactForm from "../../ContactForm/ContactForm"

const formMap = {
  connect: ConnectForm,
  contact: ContactForm,
}

const FormSection = ({ heading, body, form }) => {
  const FormComponent = formMap[form]

  if (!FormComponent) {
    return null
  }

  return (
    <div className="container">
      {(heading || body) && (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <div className="lg:col-span-8">
            {heading && (
              <h2 className="text-2xl lg:text-4xl font-semibold mb-6 lg:mb-12">
                {heading}
              </h2>
            )}
            {body && <p>{body}</p>}
          </div>
        </div>
      )}
      <FormComponent />
    </div>
  )
}

FormSection.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  form: PropTypes.oneOf(["connect", "contact"]),
}

export default FormSection
