import React from "react"
import useForm from "../../hooks/useForm"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3"

const defaultState = {
  name: "",
  title: "",
  email: "",
  location: "",
  message: "",
  "g-recaptcha-response": "",
}

// can only access GoogleReCaptcha context from a child component
const ContactForm = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
    >
      <ContactFormWithoutReCaptcha />
    </GoogleReCaptchaProvider>
  )
}

const ContactFormWithoutReCaptcha = () => {
  const { state, isValid, handleChange, handleRecaptchaVerify } = useForm(
    defaultState,
    "contact"
  )

  return (
    <form
      name="Contact"
      method="POST"
      action={process.env.GATSBY_CONTACT_FORMSPREE_ENDPOINT}
    >
      <div className="grid grid-cols-12 gap-x-4 gap-y-6 mt-12">
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="name">
            Your name *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            type="text"
            name="name"
            onChange={handleChange}
            value={state.name}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-4 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="title">
            Your job title *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            type="text"
            name="title"
            onChange={handleChange}
            value={state.title}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="email">
            Your email *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            type="email"
            name="email"
            onChange={handleChange}
            value={state.email}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-4 xl:col-span-3">
          <label className="font-semibold block mb-2" htmlFor="location">
            Your location *
          </label>
          <input
            className="bg-black bg-opacity-20 p-4 w-full"
            type="text"
            name="location"
            onChange={handleChange}
            value={state.location}
            required
          />
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-6">
          <label className="font-semibold block mb-2" htmlFor="message">
            Message *
          </label>
          <textarea
            className="bg-black bg-opacity-20 min-h-32 p-4 w-full"
            type="text"
            name="message"
            onChange={handleChange}
            value={state.message}
            required
          ></textarea>
        </div>
        <div className="col-start-1 col-span-12 xl:col-start-1 xl:col-span-6">
          <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
          <input
            type="hidden"
            name="g-recaptcha-response"
            defaultValue={state["g-recaptcha-response"]}
            required
          />
          <button
            className="uppercase tracking-wider font-semibold text-white bg-green w-full p-5 disabled:opacity-50 disabled:cursor-not-allowed"
            type="submit"
            disabled={!isValid}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
